// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
export const REQUIRED = "*Este campo es requerido.";
export const PhoneNumberProfuturo = "391-3636";
export const PhoneNumberProvinceProfuturo = "0800-11434";

export const title =
  "Bienvenido a nuestra plataforma de Generación de Clave Web";
export const disclaimerErr =
  "Debes ingresar los campos requeridos para continuar con el proceso.";
export const disclaimerErrSubmit =
  "<p>No hemos podido verificar tu tipo y número de documento. <br /> Por favor, verifica y vuelve a intentarlo.</p>";
export const disclaimerErrContacto =
  "<p>Para poder continuar con el proceso es necesario que completes esta información.</p>";
export const disclaimerErrPregunta =
  "<p>Para poder continuar con el proceso, es necesario que respondas todas las preguntas del cuestionario.</p>";
export const disclaimerErrTemporal =
  "<p>Para continuar debes seleccionar el medio por el que te haremos llegar una clave temporal.</p>";
export const disclaimerErrCodigo =
  "<p>El código ingresado es inválido, por favor, verifica y vuelve a intentar.</p>";
export const disclaimerErrClaveBlank =
  "<p>Es necesario que completes esta información para poder continuar con el proceso.</p>";
export const disclaimerErrClaveNotEqual =
  "<p>Las claves ingresadas no coinciden, por favor, verifica y vuelve a intentar.</p>";
  export const disclaimerErrClaveNotStrong =
  "<p>Las clave ingresada debe ser más fuerte, por favor, verifica y vuelve a intentar.</p>";
export const disclaimerContact = "<p style='text-align: center;'>La actualización de correo electrónico<span style='font-weight: bold;'>no modifica la forma que recibes tu Estado de Cuenta</span>, ya sea de forma física o virtual.</p><p style='text-align: center; margin-top: 5px;'>Si deseas modificar esta forma de envío, puedes hacerlo desde tu Zona Privada en nuestra página web<a href='https://www.profuturo.com.pe'>www.profuturo.com.pe</a></p>";
export const defaultErrorMessage = "Se presentó un inconveniente. Por favor, intente más tarde.";
export const defaultUnauthorizedMessage = "Su sesión ha expirado. Vuelva a intentarlo.";
// export const pickerTheme = createMuiTheme({
export const pickerTheme = createTheme({
typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#5E4696",
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: "#5E4696",
        "&:hover": {
          backgroundColor: "#5E4696",
        },
      },
      current: {
        color: "#5E4696",
      },
    },
    MuiPickersYear: {
      root: {
        "&:focus": {
          color: "#5E4696",
        },
      },
      "&$selected": {
        color: "#EE3224",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "gray",
        "&:last-child": {
          display: "none", // esto oculta el boton OK del picker
        },
      },
    },
  },
});

export const documents = {
  status: true,
  code: 1000,
  message: "Proceso realizado con éxito",
  body: [
    {
      clave: "00",
      valor: "DNI o Libreta Electoral",
    },
    {
      clave: "01",
      valor: "Carné de Extranjería",
    },
    {
      clave: "02",
      valor: "Carné Militar o Policial",
    },
    {
      clave: "03",
      valor: "Libreta del Adolescente Trabajador",
    },
    {
      clave: "04",
      valor: "Pasaporte",
    },
  ],
};

export const validLinkRedirect = [
  "/",        
  "/contacto",
  "/temporal",
  "/codigo",
  "/clave",
  "/resultado",
  "/reintentar",
  "/informativo",
];
