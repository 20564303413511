import React from "react";

function Footer(props) {
  const { title } = props;
  return (
    <div className="container__footer">
      <a href="https://www.profuturo.com.pe" target="blank">
        {title}
      </a>
    </div>
  );
}

export default Footer;
