import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "./sass/styles.scss";


// const nonce = Math.random().toString(36).substring(2, 15);
// window.__webpack_nonce__ = nonce;
// process.env.PUBLIC_NONCE = nonce;

ReactDOM.render(
  <App />,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
