import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { handleRegexDisable, getMessageError } from "../../utils/utilitaries";
import { title, validLinkRedirect } from "../../utils/constants";
import { Formik } from "formik";
import Header from "../../components/Shared/Header";
import Modal from "../../components/Modal";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Loader from "../../components/Shared/Loader";
import ModalError from "../../components/ModalError";

class Contacto extends Component {
  constructor(props) {
    super(props);
    const nroCelular = sessionStorage.getItem("ncf");
    const correo = sessionStorage.getItem("cf");
    const disclaimerContact = sessionStorage.getItem("disclaimerContact");

    this.state = {
      showModal: false,
      temporal: false,
      isLoading: false,
      errorMessage: "",
      disclaimerErrContacto: "",
      celular: nroCelular,
      correo: correo,
      err: false,
      redirect: false,
      linkRedirect: "",
      showModalError: false,
      msgServer: "",
      disclaimerContact: disclaimerContact,
      msgModal: "",
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem("tk");
    let step = sessionStorage.getItem("step");
    if (!token) {
      this.setState({
        linkRedirect: "/",
        redirect: true,
      });
    } else if (step === "2") {
      this.setState({
        linkRedirect: "/informativo",
        redirect: true,
      });
    } else if (step === "4") {
      this.setState({
        linkRedirect: "/temporal",
        redirect: true,
      });
    } else if (step === "5") {
      this.setState({
        linkRedirect: "/codigo",
        redirect: true,
      });
    } else if (step === "6") {
      this.setState({
        linkRedirect: "clave",
        redirect: true,
      });
    }
  }

  toggleModal = () => {
    this.setState({
      showModal: false,
      errorMessage: "",
      redirect: true,
      msgModal: "",
    });
  };

  toggleTemporal = () => {
    this.setState({
      showModal: false,
      errorMessage: "",
      temporal: false,
      msgModal: "",
    });
  };

  handleSendContacto = async (nroCel, email) => {
    let token = sessionStorage.getItem("tk");
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let contactApi = `${process.env.REACT_APP_PATH_SERVICE}/afiliado/datosPuntuales`;

    let contactModel = {
      numeroCelular: nroCel,
      correo: email,
    };

    const response = await Axios.post(contactApi, contactModel, {
      headers: headers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("Axios err:", error);
        this.setState({
          showModalError: true,
          isLoading: false,
          msgServer: getMessageError(error)
        });
      });

    return response;
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  handleRedirect = () => {
    const { redirect, linkRedirect } = this.state;
    if (redirect) {
      if (validLinkRedirect.indexOf(linkRedirect, 0) > -1) {
        return <Redirect to={linkRedirect} />
      }
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: false,
      msgServer: "",
      linkRedirect: "/",
      redirect: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <ModalError
          show={this.state.showModalError}
          closeCallback={this.toggleModalError}
        >
          <React.Fragment>
            <div>{this.state.msgServer}</div>
          </React.Fragment>
        </ModalError>
        <Modal
          show={this.state.showModal}
          temporal={this.state.temporal}
          closeTemporal={this.toggleTemporal}
          closeCallback={this.toggleModal}
        >
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: this.state.msgModal }} />
          </React.Fragment>
        </Modal>
        <div className={classnames("tracking bg")}>
          <React.Fragment>
            <Header />
            <Loader isLoading={this.state.isLoading} />
            {this.handleRedirect()}
            <div className={classnames("tracking__ribbon")}>
              <div className={classnames("container")}>
                <div
                  className={classnames("container__wrapper container__title")}
                >
                  {title}
                </div>
              </div>
            </div>
            <div className={classnames("container")}>
              <div className={classnames("container__wrapper p-r")}>
                <CSSTransition
                  classNames="message"
                  in={true}
                  timeout={300}
                  unmountOnExit
                  appear
                >
                  <Formik
                    initialValues={{
                      email: "",
                      nroCel: "",
                    }}
                    ref={(ref) => (this.form = ref)}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);

                      this.setState({
                        isLoading: true,
                      });

                      const { email, nroCel } = values;

                      if (!email && !nroCel) {
                        this.setState({
                          err: true,
                          disclaimerErrContacto:
                            "<p>Para poder continuar con el proceso es necesario que completes esta información.</p>",
                          isLoading: false,
                        });
                      } else {
                        if (nroCel) {
                          if (nroCel.startsWith("0")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("1")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("2")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("3")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("4")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("5")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("6")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("7")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.startsWith("8")) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else if (nroCel.length < 9) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>Debes ingresar un número de celular válido</p>",
                              isLoading: false,
                            });
                          } else {
                            this.setState({
                              err: false,
                              isLoading: false,
                            });
                          }
                        }
                        if (email) {
                          if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              email
                            )
                          ) {
                            this.setState({
                              err: true,
                              disclaimerErrContacto:
                                "<p>El correo electrónico no es válido, por favor vuelva a ingresarlo.</p>",
                              isLoading: false,
                            });
                          } else {
                            this.setState({
                              err: false,
                              isLoading: false,
                            });
                          }
                        }

                        if (!this.state.err) {
                          (async () => {
                            try {
                              const response = await this.handleSendContacto(
                                nroCel,
                                email
                              );

                              if (response !== undefined) {
                                const { rpta, mensaje, data } = response.data;
                                if (rpta === "true") {
                                  const {
                                    numeroCelular,
                                    correo,
                                    numeroCelularCifrado,
                                    correoCifrado,
                                  } = data;

                                  sessionStorage.setItem("nc", numeroCelular);
                                  sessionStorage.setItem("cr", correo);
                                  sessionStorage.setItem(
                                    "ncf",
                                    numeroCelularCifrado
                                  );
                                  sessionStorage.setItem("cf", correoCifrado);
                                  sessionStorage.setItem("step", "4");
                                  this.setState({
                                    showModal: true,
                                    isLoading: false,
                                    linkRedirect: "/temporal",
                                    msgModal: mensaje,
                                  });
                                } else {
                                  this.setState({
                                    err: true,
                                    disclaimerErrContacto: mensaje,
                                    isLoading: false,
                                  });
                                }
                              }
                            } catch (error) {
                              console.log(error);
                              // QWERTY
                              this.setState({
                                showModalError: true,
                                isLoading: false,
                                msgServer: getMessageError(error)
                              });
                            }
                          })();
                        }
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form className="questions" onSubmit={handleSubmit}>
                        <div className="document--text">
                          <span>
                            ¡Ahora es momento de actualizar tus datos!
                          </span>
                        </div>

                        <div className="contacto">
                          <div className="mt-1r">
                            <p className="contacto__label mb-1r">
                              Ingresa tu nuevo correo electrónico
                            </p>
                            <TextField
                              name="email"
                              variant="outlined"
                              fullWidth
                              className={classnames(
                                "select--icon",
                                this.state.err ? "dropdown-err" : "dropdown-true"
                              )}
                              placeholder="Correo electrónico*"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onInput={handleRegexDisable("")}
                            />
                            <p className="past">
                              Correo actual: {this.state.correo}
                            </p>
                          </div>

                          <div className="mt-2r">
                            <p className="pregunta__label mb-1r">
                              Ingresa tu nuevo número de celular
                            </p>
                            <TextField
                              name="nroCel"
                              variant="outlined"
                              fullWidth
                              className={classnames(
                                "select--icon",
                                this.state.err ? "dropdown-err" : "dropdown-true"
                              )}
                              placeholder="Número de celular*"
                              value={values.nroCel}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onInput={handleRegexDisable("[0-9]")}
                              inputProps={{
                                maxLength: 9,
                              }}
                            />
                            <p className="past">
                              Celular actual: {this.state.celular}
                            </p>
                          </div>
                        </div>

                        {this.state.err && (
                          <div className="files">
                            <div
                              className="grupo mt-1r errPregunta"
                              dangerouslySetInnerHTML={{
                                __html: this.state.disclaimerErrContacto,
                              }}
                            ></div>
                          </div>
                        )}

                        <div className="files">
                          <div className="grupo mt-2r">
                            <input
                              type="submit"
                              value="Actualizar"
                              className="btn--create"
                              disabled={isSubmitting}
                            />
                          </div>
                        </div>

                        <div
                          className="disclaimerActualizacion mt-2r"
                          dangerouslySetInnerHTML={{
                            __html: this.state.disclaimerContact,
                          }}
                        ></div>
                      </form>
                    )}
                  </Formik>
                </CSSTransition>
              </div>
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default Contacto;
