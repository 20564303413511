import React from "react";
import PropTypes from "prop-types";
import { LikeSVG } from "../../assets/images/svg";

const Modal = ({ children, show, temporal, closeTemporal, closeCallback }) => (
  <div className="modal" style={{ display: show ? "block" : "none" }}>
    {!temporal && <div className="overlay" onClick={closeCallback} />}
    {temporal && <div className="overlay" onClick={closeTemporal} />}

    <div className="modal_content">
      <figure className="like">
        <LikeSVG />
      </figure>
      {children}
      {/* <button title="Cerrar" className="close_modal" onClick={closeCallback}>
        X
      </button> */}
      <div className="btn--container">
        {temporal && (
          <input
            type="submit"
            className="btn__aceptar"
            onClick={closeTemporal}
            value="ACEPTAR"
          />
        )}
        {!temporal && (
          <input
            type="submit"
            className="btn--create"
            onClick={closeCallback}
            value="ACEPTAR"
          />
        )}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.element,
  show: PropTypes.bool,
  temporal: PropTypes.bool,
  closeTemporal: PropTypes.func,
  closeCallback: PropTypes.func,
};

// Modal.defaultProps = {
//   children: <div>Empty Modal</div>,
//   show: false,
//   temporal: false,
//   closeTemporal: () => false,
//   closeCallback: () => false
// };

export default Modal;
