import { defaultErrorMessage, defaultUnauthorizedMessage } from "./constants";

export const handleRegexDisable = (regex) => (e) => {
  if (regex) {
    const node = e.target;
    const patt =
      /^\//.test(regex) && /\/$/.test(regex) ? regex : new RegExp(regex);
    regex = /^\//.test(regex) && /\/$/.test(regex) ? `${regex}` : regex;

    if (/^\[/.test(regex) && /\$$/.test(regex)) {
      const extensivePattern = `[^${regex.slice(1, -1)}`;
      if (node.value.search(extensivePattern) !== -1) {
        node.value = node.value.slice(0, node.value.search(extensivePattern));
      }
    } else {
      const extensivePattern = `[^${regex.slice(1, regex.length)}`;
      if (node.value.search(extensivePattern) !== -1) {
        node.value = node.value.slice(0, node.value.search(extensivePattern));
      }
    }
    if (node.value && !patt.test(node.value)) {
      node.value = node.value.slice(0, node.value.length - 1);
    }
  }
};
export const NUMCONSECUTIVO_REGEX = /(0123|1234|2345|3456|4567|5678|6789|3210|4321|5432|6543|7654|8765|9876)$/;
export const NUMREPETIDO_REGEX = /(0000|1111|2222|3333|4444|5555|6666|7777|8888|9999)$/;

export const isEmpty = (obj) => {
  for (var element in obj) {
    if (obj.hasOwnProperty(element)) return false;
  }
  return true;
};

// recibe un string y devuelve el string con el primer valor en mayuscula
export const capitalizeWord = (string) => {
  const stringArray = string.trim().split(" ");
  const response = [];
  stringArray.forEach((item) =>
    response.push(
      `${item.charAt(0).toUpperCase()}${item.slice(1).toLowerCase()}`
    )
  );
  return response.join(" ");
};

export const getMessageError = (error) => {
  let msgServer = defaultErrorMessage;
  if (error.response) {
    const { status } = error.response;
    if (status === 401) {
      msgServer = defaultUnauthorizedMessage;
    }
  }
  return msgServer;
};
