import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import Header from "../../components/Shared/Header";
import Footer from "../../components/Shared/Footer";
import classnames from "classnames";
import {
  PhoneNumberProfuturo,
  PhoneNumberProvinceProfuturo,
  title,
  validLinkRedirect,
} from "../../utils/constants";
import Loader from "../../components/Shared/Loader";
import { Redirect } from "react-router";

class Informativo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      redirectMessage: "",
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem("tk");
    let step = sessionStorage.getItem("step");
    if (!token) {
      this.setState({
        redirectMessage: "/",
        redirect: true,
      });
    } else if (step === "3") {
      this.setState({
        redirectMessage: "/contacto",
        redirect: true,
      });
    } else if (step === "4") {
      this.setState({
        redirectMessage: "/temporal",
        redirect: true,
      });
    } else if (step === "5") {
      this.setState({
        redirectMessage: "/codigo",
        redirect: true,
      });
    } else if (step === "6") {
      this.setState({
        redirectMessage: "/clave",
        redirect: true,
      });
    }
  }
  handleRedirect = () => {
    const { redirect, redirectMessage } = this.state;
    if (redirect) {
      if (validLinkRedirect.indexOf(redirectMessage, 0) > -1) {
        return <Redirect to={redirectMessage} />
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className={classnames("tracking bg")}>
          <React.Fragment>
            <Header />
            <Loader isLoading={this.state.isLoading} />
            {this.handleRedirect()}
            {/* <div className={classnames("tracking__ribbon")}>
              <div className={classnames("container")}>
                <div
                  className={classnames("container__wrapper container__title")}
                >
                  {title}
                </div>
              </div>
            </div> */}
            <div style={{ margin: "auto" }} className={classnames("container")}>
              <div className={classnames("container__wrapper p-r")}>
                <CSSTransition
                  classNames="message"
                  in={true}
                  timeout={300}
                  unmountOnExit
                  appear
                >
                  <div
                    className={classnames(
                      "container__wrapper container__title"
                    )}
                  >
                    <div className="document--text informativo">
                      <span style={{fontSize:"26px",textAlign:"center !important"}}>¡Lo sentimos!</span>
                     <div style={{textAlign:"justify"}}>
                      <br />
                      <br />
                      No tenemos registrados tus datos de contacto, por favor
                      comunícate con nuestro Centro de Contacto al{" "}
                      <span>{PhoneNumberProfuturo}</span> desde Lima o al{" "}
                      <span>{PhoneNumberProvinceProfuturo}</span> desde
                      provincias, <span>opción 1. </span>Recuerda que el horario
                      de atención es de lunes a viernes de 8:00 a.m. a 6:00 p.m.
                      y sábados de 9:00 a.m. a 1:00 p.m.
                     </div>
                     
                    </div>
                  </div>
                </CSSTransition>
              </div>
            </div>
            {/* <Footer title={"www.profuturo.com.pe"} /> */}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default Informativo;
