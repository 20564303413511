import React,{ useState } from "react";



const Checkbox = ({ label,handleChange,checked,name,value,className }) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    // <div className="checkbox-wrapper"></div>
    <div>
      <input type="checkbox" checked={checked} 
         onChange={handleChange}
         name={name}
         value={value}
         className={className}
        />
    </div>
        
        // {/* <span>{label}</span> */}
    
  );
};
export default Checkbox;