import React, { Component } from "react";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Formik } from "formik";
import Header from "../../components/Shared/Header";
import Footer from "../../components/Shared/Footer";
import Checkbox from "@material-ui/core/Checkbox";
import Checkboxnative from "../../components/CheckBoxnative";

import {
  disclaimerErrTemporal,
  PhoneNumberProfuturo,
  PhoneNumberProvinceProfuturo,
  title,
  validLinkRedirect,
} from "../../utils/constants";
import { getMessageError } from "../../utils/utilitaries";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import Loader from "../../components/Shared/Loader";
import ModalError from "../../components/ModalError";

class Temporal extends Component {
  constructor(props) {
    super(props);
    const cel = sessionStorage.getItem("ncf");
    const cr = sessionStorage.getItem("cf");

    this.state = {
      option: "email",
      checkEnvioEmail: false,
      checkENvioSMS: false,
      disclaimerErrTemporal: disclaimerErrTemporal,
      error: false,
      nroCelular: cel,
      correo: cr,
      redirect: false,
      redirectMessage: "",
      isLoading: false,
      showModalError: false,
      msgServer: "",
    };
  }

  componentDidMount() {
    let token = sessionStorage.getItem("tk");
    let step = sessionStorage.getItem("step");
    if (!token) {
      this.setState({
        redirectMessage: "/",
        redirect: true,
      });
    } else if (step === "2") {
      this.setState({
        redirectMessage: "/informativo",
        redirect: true,
      });
    } else if (step === "3") {
      this.setState({
        redirectMessage: "/contacto",
        redirect: true,
      });
    } else if (step === "5") {
      this.setState({
        redirectMessage: "/codigo",
        redirect: true,
      });
    } else if (step === "6") {
      this.setState({
        redirectMessage: "/clave",
        redirect: true,
      });
    }
  }

  handleSendOTP = async (method) => {
    let token = sessionStorage.getItem("tk");

    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      tokenClaveWeb: token,
      Authorization: "Basic Y2xhdmVXZWJPVFA",
    };

    let OTPApi = `${process.env.REACT_APP_PATH_AWSSERVICE}/api/otp/generarClaveTemporal`;

    let modelOTP = {
      metodoEnvio: btoa(method),
      //numeroDocumento: sessionStorage.getItem("nd"),
      //tipoDocumento: sessionStorage.getItem("tp"),
      // codigoPersona: sessionStorage.getItem("cp"),
      // nombrePersona: sessionStorage.getItem("pn"),
      // numeroTelefono: sessionStorage.getItem("nc"),
      // email: sessionStorage.getItem("cr"),
    };

    const response = await Axios.post(OTPApi, modelOTP, {
      headers: headers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        this.setState({
          showModalError: true,
          isLoading: false,
          msgServer: getMessageError(error),
        });
      });

    return response;
  };

 

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  handleNavigateContact = () => {
    const accede = sessionStorage.getItem("ac");
    if (accede === "true") {
      sessionStorage.setItem("step", "2");
    } else {
      sessionStorage.removeItem("step");
    }
    this.setState({
      redirect: true,
      redirectMessage: accede === "true" ? "/informativo" : "/reintentar",
    });
  };

  handleRedirect = () => {
    const { redirect, redirectMessage } = this.state;
    if (redirect) {
      if (validLinkRedirect.indexOf(redirectMessage, 0) > -1) {
        return <Redirect to={redirectMessage} />;
      }
    }
  };

  handleCheckChange = (e) => {
    const formik = this.form;
    const { value, name } = e.target;

    if (value.length) {
      if (name === "email") {
        formik.setFieldValue("email", "email", false);
        formik.setFieldValue("sms", "", false);
        this.setState({ option: value });
      }
      if (name === "sms") {
        formik.setFieldValue("sms", "sms", false);
        formik.setFieldValue("email", "", false);
        this.setState({ option: value });
      }
    }
  };

  toggleModalError = () => {
    this.setState({
      showModalError: false,
      msgServer: "",
      redirectMessage: "/",
      redirect: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className={classnames("tracking bg")}>
          <React.Fragment>
            <Header />
            <ModalError
              show={this.state.showModalError}
              closeCallback={this.toggleModalError}
            >
              <React.Fragment>
                <div>{this.state.msgServer}</div>
              </React.Fragment>
            </ModalError>
            <Loader isLoading={this.state.isLoading} />
            {this.handleRedirect()}
            {/* <div className={classnames("tracking__ribbon")}>
              <div className={classnames("container")}>
                <div
                  className={classnames("container__wrapper container__title")}
                >
                  {title}
                </div>
              </div>
            </div> */}
            <div
              // style={{ paddingBottom: "150px" }}
              className={classnames("container")}
            >
              <div className={classnames("container__wrapper p-r")}>
                <CSSTransition
                  classNames="message"
                  in={true}
                  timeout={300}
                  unmountOnExit
                  appear
                >
                  <Formik
                    initialValues={{
                      initial: "",
                    }}
                    ref={(ref) => (this.form = ref)}
                    onSubmit={(values, { setSubmitting }) => {
                      setSubmitting(false);
                      const { option } = this.state;

                      if (!option) {
                        this.setState({
                          error: true,
                          isLoading: false,
                        });
                      } else {
                        try {
                          (async () => {
                            this.setState({
                              isLoading: true,
                            });

                            let envioMethod = "";
                            if (option === "email") {
                              envioMethod = "01";
                            } else {
                              envioMethod = "02";
                            }

                            const response = await this.handleSendOTP(
                              envioMethod
                            );

                            if (response !== undefined) {
                              const { rpta } = response.data;

                              if (rpta === "true") {
                                sessionStorage.setItem("mt", btoa(envioMethod));
                                sessionStorage.setItem("step", "5");
                                this.setState({
                                  redirect: true,
                                  redirectMessage: "/codigo",
                                  isLoading: false,
                                });
                              } else {
                                this.setState({
                                  error: true,
                                  disclaimerErrTemporal: `<p>Se presento un inconveniente. Por favor intenta más tarde.</p>`,
                                  isLoading: false,
                                });
                              }
                            }
                          })();
                        } catch (error) {
                          this.setState({
                            showModalError: true,
                            isLoading: false,
                            msgServer: getMessageError(error),
                          });
                        }
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form
                        className="questions temporal"
                        onSubmit={handleSubmit}
                      >
                        <div className="document--text">
                          <span>
                            Selecciona el método por el cual deseas que te
                            enviemos tu código temporal
                          </span>
                        </div>
                        {/* <div className="document--text__secundary">
                          
                        </div> */}

                        <div className="contacto mt-2r">
                          {this.state.correo && (
                            <div className="contacto pre">
                              <div className="contacto__choice">
                                <Checkboxnative
                                  label={
                                    "Recibir un correo a " + this.state.correo
                                  }
                                  checked={this.state.option === "email"}
                                  name="email"
                                  value="email"
                                  handleChange={(e) => {
                                    this.handleCheckChange(e);
                                  }}
                                  className={classnames(
                                    "checkTimes ",
                                    this.state.error ? "chErr" : "chTrue"
                                  )}
                                ></Checkboxnative>

                                {/* 
                                <Checkbox
                                  checked={this.state.option === "email"}
                                  onChange={this.handleCheckChange}
                                  name="email"
                                  className={classnames(
                                    "checkTimes",
                                    this.state.error ? "chErr" : "chTrue"
                                  )}
                                  value="email"
                                />
                                <label>
                                  Recibir un correo a {this.state.correo}
                                </label> */}
                                <label>
                                  Recibir un correo a {this.state.correo}
                                </label>
                              </div>
                            </div>
                          )}

                          {this.state.nroCelular && (
                            <div className="contacto pre">
                              <div className="contacto__choice">
                                {/* <Checkbox
                                  checked={this.state.option === "sms"}
                                  onChange={this.handleCheckChange}
                                  name="sms"
                                  className={classnames(
                                    "checkTimes",
                                    this.state.error ? "chErr" : "chTrue"
                                  )}
                                  value="sms"
                                /> */}
                                <Checkboxnative
                                  label={
                                    "Recibir un SMS a " + this.state.nroCelular
                                  }
                                  checked={this.state.option === "sms"}
                                  name="sms"
                                  value="sms"
                                  handleChange={(e) => {
                                    this.handleCheckChange(e);
                                  }}
                                  className={classnames(
                                    "checkTimes",
                                    this.state.error ? "chErr" : "chTrue"
                                  )}
                                  id="c1"
                                ></Checkboxnative>
                                <label>
                                  Recibir un SMS a {this.state.nroCelular}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>

                        {this.state.error && (
                          <div className="files">
                            <div
                              className="grupo mt-2r disclaimerTemp"
                              dangerouslySetInnerHTML={{
                                __html: this.state.disclaimerErrTemporal,
                              }}
                            ></div>
                          </div>
                        )}

                        <div className="files">
                          <div className="grupo mt-3r">
                            <input
                              type="submit"
                              value="Siguiente"
                              className="btn--create"
                              disabled={isSubmitting}
                            />
                          </div>
                        </div>
                        <div
                          style={{ textAlign: "justify" }}
                          className="disclaimer-informativo"
                        >
                          <div className="grupo mt-2r">
                            {/* <label>
                              Si el correo electrónico o número celular que
                              aparecen en pantalla no son tus datos actuales,
                              puedes actualizarlos comunicándote con nuestro
                              Centro de Contacto al{" "}
                              <b>{PhoneNumberProfuturo}</b> desde Lima o al{" "}
                              <b>{PhoneNumberProvinceProfuturo}</b> desde
                              provincias, <b>opción 1</b>. Recuerda que el
                              horario de atención es de lunes a viernes de 8:00
                              a.m. a 6:00 p.m. y sábados de 9:00 a.m. a 1:00
                              p.m.
                            </label> */}
                            {/* <label>
                              Si la información de correo electrónico y número
                              celular que aparecen en pantalla no se encuentran
                              actualizados o no los reconoces puedes comunicarte
                              con nuestro Centro de Contacto al{" "}
                              <b>{PhoneNumberProfuturo}</b> desde Lima o al{" "}
                              <b>{PhoneNumberProvinceProfuturo}</b> de lunes a
                              viernes de 8:00 a.m. a 6:00 p.m. y sábados de 9:00
                              a.m. a 1:00 p.m.
                            </label> */}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </CSSTransition>
                <div className="disclaimer-informativo">
                  <div className="grupo mt-2r">
                    {/* <label>
                      Si el correo electrónico o número celular que aparecen en
                      pantalla no son tus datos actuales, puedes actualizarlos
                      comunicándote con nuestro Centro de Contacto al{" "}
                      <b>{PhoneNumberProfuturo}</b> desde Lima o al{" "}
                      <b>{PhoneNumberProvinceProfuturo}</b> desde provincias,{" "}
                      <b>opción 1</b>. Recuerda que el horario de atención es de
                      lunes a viernes de 8:00 a.m. a 6:00 p.m. y sábados de 9:00
                      a.m. a 1:00 p.m.
                    </label> */}
                    {/* <label>
                      Si el correo electrónico y número de celular que aparecen
                      en pantalla no se encuentran actualizados o no los
                      reconoces, debes solicitar la actualización llamando a
                      nuestro Centro de Contacto al 391-3636 desde Lima o al
                      0800-11434 de lunes a viernes de 8:00 a.m. a 6:00 p.m. y
                      sábados de 9:00 a.m. a 1:00 p.m.
                    </label> */}
                  </div>
                </div>
              </div>
            </div>
            <footer className="footterTemporal">
              Si el correo electrónico y número de celular que aparecen en
              pantalla no se encuentran actualizados o no los reconoces, debes
              solicitar la actualización llamando a nuestro Centro de Contacto
              al 391-3636 desde Lima o al 0800-11434 de lunes a viernes de 8:00
              a.m. a 6:00 p.m. y sábados de 9:00 a.m. a 1:00 p.m.
            </footer>
            {/* <Footer title={"www.profuturo.com.pe"} /> */}
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default Temporal;
