import React, { Component } from "react";
import Header from "../../components/Shared/Header";
// import Footer from "../../components/Shared/Footer";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Formik } from "formik";
import {
  REQUIRED,
  disclaimerErr,
  title,
  validLinkRedirect,
  defaultErrorMessage,
} from "../../utils/constants";
import TextField from "@material-ui/core/TextField";
import { documents, pickerTheme } from "../../utils/constants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { handleRegexDisable, getMessageError } from "../../utils/utilitaries";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import ModalError from "../../components/ModalError";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { CalendarSVG } from "../../assets/images/svg";
import "./styles.css";
import "./sbp.css";
// import "./JavaScript Safety Belt - Peru.js";
// import { createTheme } from "@material-ui/core/styles";
// import { alpha } from '@material-ui/core/styles'

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: documents.body,
      disclaimerErr: disclaimerErr,
      isLoading: false,
      err: false,
      redirect: false,
      nameRedirect: "",
      errServer: false,
      disclaimerErrServer: "",
      showModalError: false,
      showModalMensajeFirst: false,
      msgServer: "",
      isVerified: false,
    };
    this.recaptchaRef = React.createRef();
    sessionStorage.clear();
  }

  toggleModal = () => {
    this.setState({
      showModalError: false,
      msgServer: "",
    });
    window.location.reload();
  };

  handleCleanFields = () => {
    try {
      const formik = this.form;
      formik.setFieldValue("nroDoc", "", false);
      formik.setFieldValue("typeDoc", "", false);
    } catch (error) {}
  };

  recaptchaLoaded(response) {
    // console.log("capcha successfully loaded");
    return response;
  }

  FechaNacimientoBisiesto = () => {
    let newDate = new Date();
    //console.log(newDate);
    return new Date(
      newDate.getFullYear() - 18,
      newDate.getMonth(),
      newDate.getDate()
    );
  };

  componentDidMount() {
    // this.sbp()
    (async () => {
      const response = await this.LoadMoadalFirst();
      if (response !== undefined) {
        const { data } = response.data;

        if (data === "S") {
          this.setState({
            showModalMensajeFirst: true,
          });
        }
      }
    })();
  }

  handleChangeDate = (value, formField) => {
    // let tempDay = "";
    // if (value.getDate() < 10) {
    //   tempDay = "0" + value.getDate().toString();
    // } else {
    //   tempDay = value.getDate();
    // }

    // let tempMonth = "";
    // if (value.getMonth() + 1 < 10) {
    //   tempMonth = "0" + (value.getMonth() + 1).toString();
    // } else {
    //   tempMonth = value.getMonth() + 1;
    // }

    // let formateCalendar = tempMonth + "/" + tempDay + "/" + value.getFullYear();

    const formik = this.form;
    // formik.setFieldValue(formField, formateCalendar, false);
    formik.setFieldValue(formField, value, false);
  };
  handleDocumentChange = (e) => {
    const value = e.target.value;
    const formField = e.target.name;
    const formik = this.form;

    if (formField === "typeDoc") {
      formik.setFieldValue(formField, value, true);
      formik.setFieldValue("nroDoc", "", false);
    }
    if (formField === "nroDoc") {
      const { typeDoc } = formik.state.values;
      let maxLengthInput = 8;
      if (typeDoc === "01" || typeDoc === "03" || typeDoc === "04")
        maxLengthInput = 12;
      if (typeDoc === "06") maxLengthInput = 10;
      formik.setFieldValue("maxLengthP", maxLengthInput, true);
      formik.setFieldValue(formField, value.toUpperCase(), true);
    }
  };

  handleRequestConfiguration = async (token) => {
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let configurationAPI = `${process.env.REACT_APP_PATH_SERVICE}/generico/configuracion`;

    const response = await Axios.get(configurationAPI, {
      headers: headers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response === undefined) {
          this.setState({
            showModalError: true,
            isLoading: false,
            msgServer: defaultErrorMessage,
          });
        } else {
          const { status, data } = error.response;
          const { mensaje } = data;
          if (status === 401) {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: mensaje,
            });
          } else {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: defaultErrorMessage,
            });
          }
        }
      });

    return response;
  };

  LoadMoadalFirst = async (token) => {
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let configurationAPI = `${process.env.REACT_APP_PATH_SERVICE}/generico/modalActualizacion`;

    const response = await Axios.get(configurationAPI, {})
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response === undefined) {
          this.setState({
            showModalError: true,
            isLoading: false,
            msgServer: defaultErrorMessage,
          });
        } else {
          const { status, data } = error.response;
          const { mensaje } = data;
          if (status === 401) {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: mensaje,
            });
          } else {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: defaultErrorMessage,
            });
          }
        }
      });

    return response;
  };

  handleAfiliadoData = async (token) => {
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    let configurationAPI = `${process.env.REACT_APP_PATH_SERVICE}/afiliado/datosAfiliado`;

    const response = await Axios.get(configurationAPI, {
      headers: headers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response === undefined) {
          this.setState({
            showModalError: true,
            isLoading: false,
            msgServer: defaultErrorMessage,
          });
        } else {
          const { status, data } = error.response;
          const { mensaje } = data;
          if (status === 401) {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: mensaje,
            });
          } else {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: defaultErrorMessage,
            });
          }
        }
      });

    return response;
  };

  handleRequest = async (typeDoc, nroDoc, fnac) => {
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // let validApi = `${process.env.REACT_APP_PATH_SERVICE}/claveweb/generartoken?grant_type=password&tipoDocumento=${typeDoc}&numeroDocumento=${nroDoc}`;
    let validApi = `${process.env.REACT_APP_PATH_SERVICE}/claveweb/generartoken?grant_type=password&client_id=clavewebDigital`;

    const body = {
      tipoDocumento: typeDoc,
      numeroDocumento: nroDoc,
      validador: fnac,
    };
    const response = await Axios.post(validApi, body, {
      headers: headers,
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response === undefined) {
          this.setState({
            showModalError: true,
            isLoading: false,
            msgServer: defaultErrorMessage,
          });
        } else {
          const { status, data } = error.response;
          const { mensaje } = data;
          if (status === 401) {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: mensaje,
            });
          } else {
            this.setState({
              showModalError: true,
              isLoading: false,
              msgServer: defaultErrorMessage,
            });
          }
        }
      });

    return response;
  };

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  handleRedirect = () => {
    const { redirect, nameRedirect } = this.state;
    if (redirect) {
      if (validLinkRedirect.indexOf(nameRedirect, 0) > -1) {
        return <Redirect to={nameRedirect} />;
      }
    }
  };
  formatEditDate = (date) => {
    let wDate = new Date(date);

    let day = wDate.getDate().toString();
    if (wDate.getDate() < 10) {
      day = "0" + day;
    }

    let month = (wDate.getMonth() + 1).toString();
    if (wDate.getMonth() + 1 < 10) {
      month = "0" + month;
    }

    let year = wDate.getFullYear().toString();
    return day + "/" + month + "/" + year;
  };

  // updateToken = () => {
  //   // you will get a new token in verifyCallback
  //   this.recaptcha.execute();
  // }
  handleVerify = (token) => {
    // console.log('Token del reCAPTCHA:', token);

    if (token) {
      this.setState({ isVerified: true });
      // console.log('Token del reCAPTCHA:', token);
    }

    // Aquí puedes enviar el token a tu servidor para verificar si el usuario es un humano
  };

  // verifyCallback = (response) => {
  //   if (response) {
  //     const formik = this.form;
  //     formik.setFieldValue("recaptcha", true, true);

  //     this.setState({
  //       isVerified: true,
  //     });
  //   }
  // };
  render() {
    const siteKey = process.env.REACT_APP_SITEKEY;

    return (
      <div className={classnames("tracking bg")}>
        <React.Fragment>
          <Header />
          <ModalError
            show={this.state.showModalError}
            // show={true}
            closeCallback={this.toggleModal}
          >
            <React.Fragment>
              <div>{this.state.msgServer}</div>
            </React.Fragment>
          </ModalError>

          <ModalError
            show={this.state.showModalMensajeFirst}
            closeCallback={() => {
              this.setState({ showModalMensajeFirst: false });
            }}
          >
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <b style={{ fontSize: "20px", paddingBottom: "20px" }}>
                  ¡Actualízate y ten una clave más segura hoy!
                </b>
                <p>Cambia tu contraseña a una de mínimo 8 caracteres.</p>
                <p>
                  {" "}
                  Si ya la actualizaste, no es necesario que lo vuelvas a hacer.
                </p>
              </div>
            </React.Fragment>
          </ModalError>

          <Loader isLoading={this.state.isLoading} />
          {this.handleRedirect()}
          {/* <div className={classnames("tracking__ribbon")}>
            <div className={classnames("container")}>
              <div
                className={classnames("container__wrapper container__title")}
              >
                {title}
              </div>
            </div>
          </div> */}
          <div
            style={{ paddingBottom: "50px" }}
            className={classnames("container")}
          >
            <div className={classnames("container__wrapper p-r")}>
              <CSSTransition
                classNames="message"
                in={true}
                timeout={300}
                unmountOnExit
                appear
              >
                <Formik
                  initialValues={{
                    typeDoc: "",
                    nroDoc: "",
                    recaptcha: false,
                    maxLengthP: 8,
                    fnac: this.FechaNacimientoBisiesto(),
                  }}
                  ref={(ref) => (this.form = ref)}
                  validate={(values) => {
                    let errors = {};

                    const { typeDoc, nroDoc, recaptcha } = values;

                    if (!typeDoc) {
                      errors.typeDoc = REQUIRED;
                    } else {
                      this.setState({
                        errServer: false,
                      });
                    }

                    if (!nroDoc) {
                      errors.nroDoc = REQUIRED;
                    } else {
                      this.setState({
                        errServer: false,
                      });
                    }

                    // if (!this.state.isVerified) {
                    //   errors.recaptcha = REQUIRED;
                    // } else {
                    //   this.setState({
                    //     errServer: false,
                    //   });
                    // }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);

                    (async () => {
                      this.setState({
                        isLoading: true,
                      });

                      const { typeDoc, nroDoc, fnac } = values;

                      try {
                        const response = await this.handleRequest(
                          typeDoc,
                          nroDoc,
                          this.formatEditDate(fnac)
                        );

                        if (response !== undefined) {
                          const { rpta } = response.data;

                          if (rpta === "true") {
                            const { data, access_token } = response.data;

                            const responseConfiguration = await this.handleRequestConfiguration(
                              access_token
                            );

                            if (responseConfiguration !== undefined) {
                              const { accede } = responseConfiguration.data;
                              if (accede === "true") {
                                sessionStorage.setItem("tk", access_token);

                                const responseAfiliado = await this.handleAfiliadoData(
                                  access_token
                                );

                                const {
                                  numeroCelular,
                                  correo,
                                  tipoDocumento,
                                  primerNombre,
                                  correoCifrado,
                                  codigoPersona,
                                  numeroDocumento,
                                  numeroCelularCifrado,
                                } = responseAfiliado.data;
                                sessionStorage.setItem("nc", numeroCelular);
                                sessionStorage.setItem("cr", correo);

                                sessionStorage.setItem("tp", tipoDocumento);
                                // sessionStorage.setItem("pn", primerNombre);
                                sessionStorage.setItem("cf", correo);
                                sessionStorage.setItem("cp", codigoPersona);
                                sessionStorage.setItem("nd", numeroDocumento);
                                sessionStorage.setItem("ncf", numeroCelular);
                                sessionStorage.setItem("ac", accede);

                                if (numeroCelular === "" && correo === "") {
                                  sessionStorage.setItem("step", "2");
                                  this.setState({
                                    redirect: true,
                                    nameRedirect: "/informativo",
                                    isLoading: false,
                                  });
                                } else {
                                  sessionStorage.setItem("step", "4");
                                  this.setState({
                                    redirect: true,
                                    nameRedirect: "/temporal",
                                    isLoading: false,
                                  });
                                }
                              } else {
                                const responseAfiliado = await this.handleAfiliadoData(
                                  access_token
                                );
                                const {
                                  numeroCelular,
                                  correo,
                                } = responseAfiliado.data;

                                const {
                                  mensajeAcceso,
                                } = responseConfiguration.data;

                                sessionStorage.setItem(
                                  "disclaimerExcede",
                                  mensajeAcceso
                                );

                                if (numeroCelular === "" && correo === "") {
                                  this.setState({
                                    redirect: true,
                                    nameRedirect: "/reintentar",
                                    isLoading: false,
                                  });
                                } else {
                                  const { access_token } = response.data;
                                  sessionStorage.setItem("tk", access_token);

                                  const {
                                    numeroCelular,
                                    correo,
                                    tipoDocumento,
                                    primerNombre,
                                    correoCifrado,
                                    codigoPersona,
                                    numeroDocumento,
                                    numeroCelularCifrado,
                                  } = responseAfiliado.data;
                                  sessionStorage.setItem("nc", numeroCelular);
                                  sessionStorage.setItem("cr", correo);

                                  sessionStorage.setItem("tp", tipoDocumento);
                                  // sessionStorage.setItem("pn", primerNombre);
                                  sessionStorage.setItem("cf", correo);
                                  sessionStorage.setItem("cp", codigoPersona);
                                  sessionStorage.setItem("nd", numeroDocumento);
                                  sessionStorage.setItem("ncf", numeroCelular);
                                  sessionStorage.setItem("ac", accede);
                                  sessionStorage.setItem("step", "4");

                                  this.setState({
                                    redirect: true,
                                    nameRedirect: "/temporal",
                                    isLoading: false,
                                  });
                                }
                              }
                            }
                          }

                          this.handleCleanFields();
                        } else {
                          this.handleCleanFields();
                        }
                      } catch (error) {
                        this.setState({
                          showModalError: true,
                          isLoading: false,
                          msgServer: getMessageError(error),
                        });
                      }
                    })();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                  }) => (
                    <form className="login" onSubmit={handleSubmit}>
                      <div className="document--text">
                        <span>Ingresa tus datos </span>
                      </div>
                      <div className="files">
                        <div className="grupo mt-2r">
                          {/* <CspProvider nonce="rAnd0m123" policies={policies}></CspProvider> */}
                          <Select
                            name="typeDoc"
                            displayEmpty
                            variant="outlined"
                            className={classnames(
                              "select--login",
                              errors.typeDoc ? "dropdown-err" : "dropdown-true"
                            )}
                            fullWidth
                            value={values.typeDoc}
                            onChange={this.handleDocumentChange}
                            onBlur={handleBlur}
                            inputProps={{
                              nonce: "rAnd0m123",
                            }}
                          >
                            <MenuItem disabled value={""} nonce="rAnd0m123">
                              <span>Selecciona tu tipo de documento</span>
                            </MenuItem>
                            {this.state.documents &&
                              this.state.documents.length &&
                              this.state.documents.map((document) => (
                                <MenuItem
                                  key={document.clave}
                                  value={document.clave}
                                >
                                  {document.valor}
                                </MenuItem>
                              ))}
                          </Select>
                        </div>
                      </div>
                      <div className="files">
                        <div className="grupo mt-1r">
                          <TextField
                            name="nroDoc"
                            variant="outlined"
                            className={classnames(
                              "nrodocument",
                              errors.nroDoc ? "dropdown-err" : "dropdown-true"
                            )}
                            fullWidth
                            placeholder="Ingresa tu número de documento"
                            value={values.nroDoc.toUpperCase()}
                            onBlur={handleBlur}
                            onChange={this.handleDocumentChange}
                            onInput={
                              values.typeDoc === "00" || values.typeDoc === "02"
                                ? handleRegexDisable("[0-9]")
                                : handleRegexDisable("[a-zA-Z0-9ñÑ-]")
                            }
                            inputProps={{
                              nonce: "rAnd0m123",
                              maxLength: values.maxLengthP,
                            }}
                          />
                        </div>
                      </div>

                      {errors.typeDoc && errors.nroDoc && (
                        <div className="files">
                          <div className="grupo mt-1r disclaimerTemporal">
                            {this.state.disclaimerErr}
                          </div>
                        </div>
                      )}

                      {this.state.errServer && (
                        <div className="files">
                          <div className="grupo mt-1r disclaimerTemporal">
                            {this.state.disclaimerErrServer}
                          </div>
                        </div>
                      )}
                      <div className="files">
                        <div className="grupo fd-c">
                          <br></br>
                          <label className="label--key mb-1r">
                            Fecha de nacimiento
                          </label>
                          <MuiThemeProvider theme={pickerTheme}>
                            <DatePicker
                              id="fnac"
                              cancelLabel="Cancelar"
                              okLabel="Aceptar"
                              openTo="year"
                              className="calendar"
                              format="dd/MM/yyyy"
                              fullWidth
                              views={["year", "month", "date"]}
                              onChange={(value) =>
                                this.handleChangeDate(value, "fnac")
                              }
                              maxDate={this.FechaNacimientoBisiesto()}
                              value={values.fnac}
                              InputProps={{
                                nonce: "rAnd0m123",
                                style: {
                                  height: 50,
                                  backgroundColor: "#FFFFFF",
                                  borderRadius: "3px",
                                  border: "1px solid #C4C4C4",
                                  paddingLeft: "13px",
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <figure
                                      className={classnames(
                                        "input__adornment__date"
                                      )}
                                    >
                                      <CalendarSVG />
                                    </figure>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </MuiThemeProvider>
                        </div>
                      </div>

                      <div className="files">
                        <div className="grupo mt-1r">
                          <div sandbox="allow-scripts allow-popups allow-same-origin">
                            <div
                              className="g-recaptcha"
                              data-sitekey="{siteKey}"
                              data-callback="onSubmit"
                              data-action="submit"
                            ></div>
                          </div>
                        </div>
                      </div>
                      {/* {errors.recaptcha && (
                        <div className="files">
                          <div
                            className="grupo"
                            style={{
                              color: "#EE3224",
                              justifyContent: "center",
                            }}
                          >
                            *La verificacion Recaptcha no valido
                          </div>
                        </div>
                      )} */}

                      <div className="files">
                        <div className="grupo mt-2r">
                          <input
                            type="submit"
                            value="Siguiente"
                            className={
                              isValid ? "btn--create" : "btn--create_disabled"
                            }
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </CSSTransition>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              placeItems: "center",
            }}
          >
            <div className="disclaimer mb-3r">
              <p className="disclaimer__remember">
                Con tu clave web podrás ingresar a todas las plataformas de
                Profuturo de forma segura, no es necesario que crees una nueva
                cada vez que ingresas.
              </p>
            </div>

            <p className="textProtection">
              <a
                href="https://www.profuturo.com.pe/tratamiento-a-los-datos-personales"
                target="blank"
                rel="noopener noreferrer"
              >
                Ley de protección de datos
              </a>{" "}
            </p>
          </div>

          {/* <Footer /> */}
        </React.Fragment>
      </div>
    );
  }
}

export default Login;
