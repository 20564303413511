import React, { Component } from 'react';
import classnames from 'classnames';
import { LogoSVG2 } from '../../../assets/images/svg'
import { Redirect } from "react-router-dom";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
         }
    }
    render() {
        return(
            <div className={classnames('container')} style={{paddingTop:"10px"}}>
                <div className={classnames('container__wrapper')}>
                    <div className={classnames('tracking__header')}>
                        <div className={classnames('f-c')}>
                            <figure className="logo">
                                <LogoSVG2 onClick={()=>{
                                    this.setState({
                                        redirect: true,
                                    })
                                   
                                }                                     
                                }/>
                            </figure>
                        </div>
                    </div>
                </div>
            {this.state.redirect&&(<Redirect to={"/"} />)}


            </div>
        );
    }
}

export default Header;