import React, { Component } from "react";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import Header from "../../components/Shared/Header";
import { Formik } from "formik";
import { LikeSVG } from "../../assets/images/svg";
import { title } from "../../utils/constants";
import { Redirect } from "react-router";

class Resultado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
    sessionStorage.clear();
  }
  handleSubmit = () => {
    return <Redirect to="/" />;
  };

  render() {
    return (
      <div className={classnames("tracking bg")}>
        {/* {this.handleRedirect()} */}

        <React.Fragment>
          <Header />
          {/* <div className={classnames("tracking__ribbon")}>
            <div className={classnames("container")}>
              <div
                className={classnames("container__wrapper container__title")}
              >
                {title}
              </div>
            </div>
          </div> */}

          <div
            style={{ paddingTop: "10%" }}
            className={classnames("container")}
          >
            <div className={classnames("container__wrapper p-r")}>
              <figure style={{ textAlign: "center" }}>
                <LikeSVG />
              </figure>

              <div className="document--text mt-2r">
                <span>¡Listo, tu clave web es más segura!</span>
              </div>

              <div className="document--text mt-2r">
                Con tu nueva clave podrás acceder a todas las plataformas de
                Profuturo AFP
              </div>

              {/* <div className="mt-3r links--resultado">
                        <div
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                          className="try--link"
                        >
                          <a
                            href="https://www.fondosprofuturo.com.pe/FondosProfuturo/#/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Quiero hacer un Aporte Voluntario
                          </a>
                        </div>
                        <div
                          style={{
                            fontSize: "14px",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                          className="try--link"
                        >
                          <a
                            href="https://enlinea.profuturo.com.pe/zonaprivada/loading"
                            target="blank"
                            rel="noopener noreferrer"
                          >
                            Quiero ingresar a mi Zona Privada
                          </a>
                        </div>
                        <div style={{fontSize: '14px', marginTop: '15px', marginBottom: '15px'}} className="try--link">Quiero consultar mi Estado de Cuenta</div>
                      </div> */}
              <div className={classnames("grupo mt-2r disclaimerClaveBtn")}>
                <form action="." method="GET">
                  <input
                    type="submit"
                    value="Finalizar"
                    className="btn--create"
                    onClick={() => {
                      // this.state({ redirect: true });
                    }}
                    // disabled={true}
                  />
                </form>
              </div>
              {/* </CSSTransition> */}
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default Resultado;
