import React, { Component } from "react";
import { InfoSVG } from '../../../assets/images/svg';

class Banner extends Component {
  handleHideBanner = () => {
    const { hideBanner } = this.props;
    hideBanner();
  };

  render() {
    const { mensaje } = this.props;
    return (
      <div className="banner">
        <div className="banner__info">
            <figure>
                <InfoSVG />
            </figure>
        </div>
        <div onClick={this.handleHideBanner} className="banner__close">
          x
        </div>
        <div dangerouslySetInnerHTML={{ __html: mensaje }} />
      </div>
    );
  }
}

export default Banner;