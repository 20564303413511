import React, { Component } from "react";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { Formik } from "formik";
import Header from "../../components/Shared/Header";
import Banner from "../../components/Shared/Banner";
import { handleRegexDisable, getMessageError } from "../../utils/utilitaries";

import { KeySVG } from "../../assets/images/svg";
import {
  disclaimerErrCodigo,
  title,
  validLinkRedirect,
} from "../../utils/constants";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import Loader from "../../components/Shared/Loader";
import ModalError from "../../components/ModalError";

class Codigo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      disclaimerErrCodigo: "",
      redirect: false,
      linkRedirect: "",
      showBanner: false,
      mensajeBanner: "",
      isLoading: false,
      showMethodF: "",
      showModalError: false,
      msgServer: "",
      isVerified: false,
    };
  }
  // recaptchaLoaded(response) {
  //   // console.log("capcha successfully loaded");
  //   return response;
  // }
  // verifyCallback = (response) => {
  //   if (response) {
  //     const formik = this.form;
  //     formik.setFieldValue("recaptcha", true, true);

  //     this.setState({
  //       isVerified: true,
  //     });
  //   }
  // };
  componentDidMount() {
    let token = sessionStorage.getItem("tk");
    let step = sessionStorage.getItem("step");
    if (!token) {
      this.setState({
        linkRedirect: "/",
        redirect: true,
      });
    } else if (step === "2") {
      this.setState({
        linkRedirect: "/informativo",
        redirect: true,
      });
    } else if (step === "3") {
      this.setState({
        linkRedirect: "/contacto",
        redirect: true,
      });
    } else if (step === "4") {
      this.setState({
        linkRedirect: "/temporal",
        redirect: true,
      });
    } else if (step === "5") {
      if (atob(sessionStorage.getItem("mt")) === "01") {
        this.setState({
          showMethodF: "tu correo electrónico",
        });
      } else {
        this.setState({
          showMethodF: "SMS",
        });
      }
    } else if (step === "6") {
      this.setState({
        linkRedirect: "/clave",
        redirect: true,
      });
    }
  }

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  handleRedirect = () => {
    const { redirect, linkRedirect } = this.state;
    if (redirect) {
      if (validLinkRedirect.indexOf(linkRedirect, 0) > -1) {
        return <Redirect to={linkRedirect} />;
      }
    }
  };

  handleChangeCode = (e) => {
    const formik = this.form;
    const { name, value } = e.target;

    switch (name) {
      case "numberone":
        if (value) {
          document.getElementById("numbertwo").focus();
        }
        formik.setFieldValue(name, value, "");
        break;
      case "numbertwo":
        if (value) {
          document.getElementById("numberthree").focus();
        } else {
          document.getElementById("numberone").focus();
        }
        formik.setFieldValue(name, value, "");
        break;
      case "numberthree":
        if (value) {
          document.getElementById("numberfour").focus();
        } else {
          document.getElementById("numbertwo").focus();
        }
        formik.setFieldValue(name, value, "");
        break;
      case "numberfour":
        if (value) {
          document.getElementById("numberfive").focus();
        } else {
          document.getElementById("numberthree").focus();
        }
        formik.setFieldValue(name, value, "");
        break;
      case "numberfive":
        if (!value) {
          document.getElementById("numberfour").focus();
        }
        formik.setFieldValue(name, value, "");
        break;
      default:
        return name;
    }
  };

  // handleSendCode = async (codeComplete) => {
  handleSendCode = (codeComplete) => {
    /*let token = sessionStorage.getItem("tk");
    let codePersona = sessionStorage.getItem("cp");
    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Basic Y2xhdmVXZWJPVFA`,
      tokenClaveWeb: token,
      claveTemporal: codeComplete,
      tokenCaptchaClaveWeb: tokenCaptchaClaveWeb,
    };
    let modelCodigo = {
      codigoPersona: codePersona,
    };
    const response = await Axios.post(`${process.env.REACT_APP_SITEKEY}/api/otp/validarClaveTemporal`, modelCodigo, {
        headers: headers,
    })
    .then((response) => {
      return response;
    });
    return response;*/
  };

  handleReSendCode = async () => {
    let token = sessionStorage.getItem("tk");

    this.setState({
      isLoading: true,
    });

    var headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      tokenClaveWeb: token,
      Authorization: "Basic Y2xhdmVXZWJPVFA",
    };

    let OTPApi = `${process.env.REACT_APP_PATH_AWSSERVICE}/api/otp/generarClaveTemporal`;

    let modelOTP = {
      metodoEnvio: sessionStorage.getItem("mt"),
      // codigoPersona: sessionStorage.getItem("cp"),
      // nombrePersona: sessionStorage.getItem("pn"),
      // numeroTelefono: sessionStorage.getItem("nc"),
      // email: sessionStorage.getItem("cr"),
    };

    const formik = this.form;
    formik.setFieldValue("numberone", "", false);
    formik.setFieldValue("numbertwo", "", false);
    formik.setFieldValue("numberthree", "", false);
    formik.setFieldValue("numberfour", "", false);
    formik.setFieldValue("numberfive", "", false);

    let showMethod = "";
    if (atob(sessionStorage.getItem("mt")) === "01") {
      showMethod = sessionStorage.getItem("cf");
    } else {
      showMethod = sessionStorage.getItem("ncf");
    }

    const response = await Axios.post(OTPApi, modelOTP, {
      headers: headers,
    })
      .then((response) => {
        this.setState({
          error: false,
        });

        this.setState({
          showBanner: true,
          mensajeBanner: `<p>Te hemos enviado el código temporal a: ${showMethod}</span></p>`,
          isLoading: false,
        });

        return response;
      })
      .catch((error) => {
        this.setState({
          showModalError: true,
          isLoading: false,
          msgServer: getMessageError(error),
        });
      });

    return response;
  };

  handleHideBanner = () => {
    this.setState({
      showBanner: false,
      mensajeBanner: "",
    });
  };

  toggleModalError = () => {
    this.setState({
      showModalError: false,
      msgServer: "",
      linkRedirect: "/",
      redirect: true,
    });
  };

  handleCleanCode = () => {
    const formik = this.form;
    formik.setFieldValue("numberone", "", false);
    formik.setFieldValue("numbertwo", "", false);
    formik.setFieldValue("numberthree", "", false);
    formik.setFieldValue("numberfour", "", false);
    formik.setFieldValue("numberfive", "", false);
    document.getElementById("numberone").focus();
  };

  handleVerify = (token) => {
    if (token) {
      // const formik = this.form;
      // formik.setFieldValue("recaptcha", true, false)
      this.setState({
        isVerified: true,
      });
    }
  };

  render() {
    const siteKey = `${process.env.REACT_APP_SITEKEY}`;

    return (
      <React.Fragment>
        <div className={classnames("tracking bg")}>
          <React.Fragment>
            <Header />
            <ModalError
              show={this.state.showModalError}
              closeCallback={this.toggleModalError}
            >
              <React.Fragment>
                <div>{this.state.msgServer}</div>
              </React.Fragment>
            </ModalError>
            <Loader isLoading={this.state.isLoading} />
            {this.handleRedirect()}
            {/* <div className={classnames("tracking__ribbon")}>
              <div className={classnames("container")}>
                <div
                  className={classnames("container__wrapper container__title")}
                >
                  {title}
                </div>
              </div>
            </div> */}
            <div className={classnames("container")}>
              <div className={classnames("container__wrapper p-r")}>
                <CSSTransition
                  classNames="message"
                  in={true}
                  timeout={300}
                  unmountOnExit
                  appear
                >
                  <Formik
                    initialValues={{
                      numberone: "",
                      numbertwo: "",
                      numberthree: "",
                      numberfour: "",
                      numberfive: "",
                    }}
                    validate={(values) => {
                      let errors = {};
                    }}
                    ref={(ref) => (this.form = ref)}
                    onSubmit={(values, { setSubmitting }) => {
                      this.setState({
                        isLoading: true,
                      });

                      setSubmitting(false);

                      const {
                        numberone,
                        numbertwo,
                        numberthree,
                        numberfour,
                        numberfive,
                      } = values;

                      if (
                        !numberone ||
                        !numbertwo ||
                        !numberthree ||
                        !numberfour ||
                        !numberfive
                      ) {
                        this.setState({
                          error: true,
                          isLoading: false,
                          disclaimerErrCodigo: disclaimerErrCodigo,
                        });
                      } else {
                        try {
                          (async () => {
                            const codeComplete =
                              numberone +
                              numbertwo +
                              numberthree +
                              numberfour +
                              numberfive;
                            // window.grecaptcha.ready(_ => {
                            window.grecaptcha
                              .execute(`${process.env.REACT_APP_SITEKEY}`, {
                                action: "validarClaveTemporal",
                              })
                              .then((tokenCaptchaClaveWeb) => {
                                let token = sessionStorage.getItem("tk");
                                let codePersona = sessionStorage.getItem("cp");
                                var headers = {
                                  "Content-Type": "application/json",
                                  Accept: "application/json",
                                  Authorization: `Basic Y2xhdmVXZWJPVFA`,
                                  tokenClaveWeb: token,
                                  claveTemporal: codeComplete,
                                  tokenCaptchaClaveWeb: tokenCaptchaClaveWeb,
                                };

                                // var headers = {
                                //   "Content-Type": "application/json",
                                //   Accept: "application/json",
                                //   Authorization: `Bearer ` + token,
                                // };

                                let modelCodigo = {
                                  codigoPersona: codePersona,
                                };

                                Axios.post(
                                  `${process.env.REACT_APP_PATH_AWSSERVICE}/api/otp/validarClaveTemporal`,

                                  modelCodigo,
                                  {
                                    headers: headers,
                                  }
                                )
                                  .then((response) => {
                                    if (response !== undefined) {
                                      const { rpta, mensaje } = response.data;

                                      if (rpta === "true") {
                                        sessionStorage.setItem("step", "6");
                                        this.setState({
                                          redirect: true,
                                          isLoading: false,
                                          linkRedirect: "/clave",
                                        });
                                      } else {
                                        setTimeout(() => {
                                          this.setState({
                                            error: true,
                                            disclaimerErrCodigo: mensaje,
                                            isLoading: false,
                                          });
                                        }, 5000);
                                      }
                                    }
                                  })
                                  .catch((error) => {
                                    this.setState({
                                      showModalError: true,
                                      isLoading: false,
                                      msgServer: getMessageError(error),
                                    });
                                  });
                              });

                            // });
                          })();
                        } catch (error) {
                          setTimeout(() => {
                            this.setState({
                              showModalError: true,
                              isLoading: false,
                              msgServer: getMessageError(error),
                            });
                          }, 0);
                        }
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form className="questions" onSubmit={handleSubmit}>
                        <div className="document--text">
                          <span>Ingresa tu código temporal</span>
                        </div>

                        <div className="codigo mt-5r">
                          <figure style={{ paddingRight: "20px" }}>
                            <KeySVG />
                          </figure>
                          <div className="codigo__bloque">
                            <input
                              type="text"
                              onChange={this.handleChangeCode}
                              onBlur={handleBlur}
                              value={values.numberone}
                              name="numberone"
                              id="numberone"
                              className="codigo__bloque code"
                              onInput={handleRegexDisable("[a-zA-Z0-9ñÑ-]")}
                              maxLength={1}
                            />
                          </div>
                          <div className="codigo__bloque">
                            <input
                              type="text"
                              onChange={this.handleChangeCode}
                              onBlur={handleBlur}
                              value={values.numbertwo}
                              name="numbertwo"
                              id="numbertwo"
                              className="codigo__bloque code"
                              onInput={handleRegexDisable("[a-zA-Z0-9ñÑ-]")}
                              maxLength={1}
                            />
                          </div>
                          <div className="codigo__bloque">
                            <input
                              type="text"
                              onChange={this.handleChangeCode}
                              onBlur={handleBlur}
                              value={values.numberthree}
                              name="numberthree"
                              id="numberthree"
                              className="codigo__bloque code"
                              onInput={handleRegexDisable("[a-zA-Z0-9ñÑ-]")}
                              maxLength={1}
                            />
                          </div>
                          <div className="codigo__bloque">
                            <input
                              type="text"
                              onChange={this.handleChangeCode}
                              onBlur={handleBlur}
                              value={values.numberfour}
                              name="numberfour"
                              id="numberfour"
                              className="codigo__bloque code"
                              onInput={handleRegexDisable("[a-zA-Z0-9ñÑ-]")}
                              maxLength={1}
                            />
                          </div>
                          <div className="codigo__bloque">
                            <input
                              type="text"
                              onChange={this.handleChangeCode}
                              onBlur={handleBlur}
                              value={values.numberfive}
                              name="numberfive"
                              id="numberfive"
                              className="codigo__bloque code"
                              onInput={handleRegexDisable("[a-zA-Z0-9ñÑ-]")}
                              maxLength={1}
                            />
                          </div>
                        </div>

                        <div className="files">
                          <div className="grupo mt-1r">
                            <label
                              className="try--link"
                              onClick={this.handleCleanCode}
                            >
                              Limpiar
                            </label>
                          </div>
                        </div>

                        {this.state.error && (
                          <div className="files">
                            <div
                              className="grupo mt-2r disclaimerTemp"
                              dangerouslySetInnerHTML={{
                                __html: this.state.disclaimerErrCodigo,
                              }}
                            ></div>
                          </div>
                        )}
                        <div className="files">
                          <div className="grupo mt-1r">
                            <div
                              className="g-recaptcha"
                              data-sitekey="{siteKey}"
                              data-callback="onSubmit"
                              data-action="submit"
                            ></div>
                          </div>
                        </div>
                        <div className="files">
                          <div className="grupo mt-2r">
                            <input
                              type="submit"
                              value="Siguiente"
                              className="btn--create"
                              disabled={
                                isSubmitting || this.state.isLoading === true
                              }
                            />
                          </div>
                        </div>

                        <div className="files">
                          <div className="grupo mt-2r">
                            <label
                              className="try--link"
                              onClick={this.handleReSendCode}
                            >
                              Volver a enviar el código
                            </label>
                          </div>
                        </div>

                        {this.state.showBanner && (
                          <div className="files">
                            <div className="grupo mt-3r">
                              <Banner
                                mensaje={this.state.mensajeBanner}
                                hideBanner={this.handleHideBanner}
                              />
                            </div>
                          </div>
                        )}
                      </form>
                    )}
                  </Formik>
                </CSSTransition>
              </div>
            </div>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  }
}

export default Codigo;
