import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Login from "../views/Login";
import Contacto from "../views/Contacto";
import Temporal from "../views/Temporal";
import Codigo from "../views/Codigo";
import Clave from "../views/Clave";
import Resultado from "../views/Resultado";
import Reintentar from "../views/Reintentar";
import Informativo from "../views/Informativo";
// fuentes de miryan
class Router extends Component {
  render() {
    return (
      <HashRouter>
        <div className="main">
          <Switch>
            <Route exact path="/" component={Login} />
            {/* <Route exact path="/" component={Resultado} /> */}
            <Route exact path="/contacto" component={Contacto} />
            <Route exact path="/temporal" component={Temporal} />
            <Route exact path="/codigo" component={Codigo} />
            <Route exact path="/clave" component={Clave} />
            <Route exact path="/resultado" component={Resultado} />
            <Route exact path="/reintentar" component={Reintentar} />
            <Route exact path="/informativo" component={Informativo} />
            <Route
              path="*"
              render={() => <div> COMPRUEBE LA DIRECCIÓN DE LA URL. </div>}
            />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default Router;
