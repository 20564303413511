import React, { useState } from "react";
// import { useForm } from "react-hook-form";
import "./style.css";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Eyes,EyesClose } from "../../assets/images/svg";

// const eye = <FontAwesomeIcon icon={faEye} />;

const TextFieldNativePassword = ({
  placeholder,
  name,
  onChange,
  value,
  onBlur,
  error,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="App">
      <div className="pass-wrapper">
        <input
          className="inputClass"
          placeholder={placeholder}
          name={name}
          type={passwordShown ? "text" : "password"}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          error={error}
          // ref={register({ required: "This is required." })}
        />
        <i onClick={togglePasswordVisiblity}>
            {passwordShown?(<Eyes></Eyes>):(<EyesClose></EyesClose>)}
          
        </i>
      </div>
    </div>
  );
};

export default TextFieldNativePassword;
