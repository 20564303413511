import React from "react";
import PropTypes from "prop-types";

const ModalError = ({ children, show, closeCallback }) => (
  <div className="modal" style={{ display: show ? "block" : "none" }}>
    <div className="overlay" onClick={closeCallback} />

    <div className="modal_content">
      {children}

      <div className="btn--container">
        <input
          type="submit"
          className="btn--create"
          onClick={closeCallback}
          value="Aceptar"
        />
      </div>
    </div>
  </div>
);

ModalError.propTypes = {
  children: PropTypes.element,
  show: PropTypes.bool,
  closeCallback: PropTypes.func,
};

// Modal.defaultProps = {
//   children: <div>Empty Modal</div>,
//   show: false,
//   temporal: false,
//   closeTemporal: () => false,
//   closeCallback: () => false
// };

export default ModalError;

// import React from "react";
// import PropTypes from "prop-types";
// import { CautionSVG } from "../../assets/images/svg";

// const ModalError = ({ children, show, closeCallback }) => (
//   <div className="modal" style={{ display: show ? "block" : "none" }}>
//     <div className="overlay" onClick={closeCallback} />
//     <div className="modal_content">
//       <figure className="like">
//         <CautionSVG />
//       </figure>
//       {children}

//       <div className="btn--container">
//         <input
//           type="submit"
//           className="btn--create"
//           onClick={closeCallback}
//           value="Aceptar"
//         />
//       </div>
//     </div>
//   </div>
// );

// ModalError.propTypes = {
//   children: PropTypes.element,
//   show: PropTypes.bool,
//   closeCallback: PropTypes.func,
// };

// export default ModalError;
