import React, { Component } from "react";
import classnames from "classnames";
import Header from "../../components/Shared/Header";
import { CSSTransition } from "react-transition-group";
import { QuestionsError } from "../../assets/images/svg";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import { title } from "../../utils/constants";

class Reintentar extends Component {
  constructor(props) {
    const disclaimerExcede = sessionStorage.getItem("disclaimerExcede");
    super(props);
    this.state = {
      redirect: false,
      disclaimerExcede: disclaimerExcede,
    };
    sessionStorage.removeItem("tk");
  }

  setRedirect = () => {
    this.setState({
      redirect: true,
    });
  };

  handleRedirect = () => {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <div className={classnames("tracking bg")}>
        <React.Fragment>
          <Header />
          {this.handleRedirect()}
          <div className={classnames("tracking__ribbon")}>
            <div className={classnames("container")}>
              <div
                className={classnames("container__wrapper container__title")}
              >
                {title}
              </div>
            </div>
          </div>
          <div className={classnames("container")}>
            <div className={classnames("container__wrapper p-r")}>
              <CSSTransition
                classNames="message"
                in={true}
                timeout={300}
                unmountOnExit
                appear
              >
                <Formik
                  initialValues={{
                    initial: "",
                  }}
                  ref={(ref) => (this.form = ref)}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    this.setState({
                      redirect: true,
                    });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form className="questions" onSubmit={handleSubmit}>
                      <figure style={{ textAlign: "center" }}>
                        <QuestionsError />
                      </figure>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.disclaimerExcede,
                        }}
                      ></div>

                      <div className="files">
                        <div className="grupo mt-3r">
                          <input
                            type="submit"
                            value="Salir"
                            className="btn--create"
                            disabled={isSubmitting}
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </CSSTransition>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default Reintentar;
